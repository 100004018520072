import React, { useState } from "react";
import ProductSelectionTop from './productSelectionTop'
import ProductSelectionBottom from './productSelectionBottom'
import data from '../../api/greelineProducts.json';

function ProductSelection() {
  return (
    <div className="productSelection">
        {/* Top Area where we will have a select box */}
        <ProductSelectionTop data={data} />

        {/* Bottom Area where we will have the Carousel */}
        <ProductSelectionBottom data={data} />
    </div>
  );
}

export default ProductSelection;
