import { createContext, useState } from "react";

export const ProductContext = createContext();

//This Context will store the current selected product
function ProductContextProvider(props){
    const [product, setProduct] = useState(0);

    //Change the Selected Product
    function changeProduct(val){
        setProduct(val)
    }
    const value = {product, changeProduct}

    return (
        <ProductContext.Provider value={value}>
            {props.children}
        </ProductContext.Provider>
    );
}

export default ProductContextProvider;