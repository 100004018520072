import React, { useState, useContext } from "react";
import { v4 } from "uuid";
import Card from 'react-bootstrap/Card';
import { AddedEquipmentContext } from "../../context/addedEquipmentContext"

function Equipment(props) {
  const {addedEquipment, addEquipment} = useContext(AddedEquipmentContext)
  const [item, addItem] = useState({})

  const equipment=props.equipment;

  const addItemToArray = (obj)=>{
    //Add Equipment to State and Context as well
    // obj.id = v4();
    // console.log(JSON.stringify(obj));

    addItem(obj)
    addEquipment(obj)
  }

  return (
    <a href="#/" className="equipmentLink" onClick={()=>addItemToArray(equipment)}>
    <Card className="equipment">
      <div className="chooseEquipmentImgContainer">
        <Card.Img variant="top" src={`${equipment.image}`} className="equipmentImage" />
      </div>
      <Card.Body className="equipmentBody">
        <Card.Title className="equipmentTitle">{equipment.name}</Card.Title>
      </Card.Body>
    </Card>
    </a>
  );
}

export default Equipment;
