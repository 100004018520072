import React, { useState } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Equipment from "./equipment";
import data from '../../api/equipments.json';

function ChooseEquipments() {

  // console.log(JSON.stringify(data));
  // const result = data?.map((l,i) => {
  //   l.data = l.data.map(d => ({id:Math.floor(Math.random() * Date.now()), ...d}));
  //   return l;
  // })
  // console.log(JSON.stringify(result));
  return (
    <div className="chooseEquipments">
        <Row>
            {data?.map( (equipment, i) => (
                <Col xs={6} sm={4} md={3} lg={2}>
                    {/* Equipment Component */}
                    <Equipment equipment={equipment} />
                </Col>
            ))}
        </Row>
    </div>
  );
}

export default ChooseEquipments;
