import React, { useState, useEffect, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { ProductContext } from "../../context/productContext"
import data from '../../api/greelineProducts.json';
import _, { set } from 'lodash';
import { AddedEquipmentContext } from "../../context/addedEquipmentContext"

function Meter() {
  const {product, changeProduct} = useContext(ProductContext)
  const {addedEquipment, addEquipment} = useContext(AddedEquipmentContext)
  const [bar1, setBar1] = useState(data[0]);
  const [bar2, setBar2] = useState(0);
  
  useEffect(()=>{
    //Get the Power of selected product from the JSON
    let prod = _.find(data, {'id': parseInt(product)});
    setBar1(prod);


    //Count the sum of Consumption
    let aeCount = 0;
    addedEquipment.map((aE)=>{
      aeCount+=parseFloat(aE.usage);
    });
    setBar2(aeCount);
  }, [product, addedEquipment]);

  return (
    <div className="meter">
      <Row>
            <Col className="topMeter">
              <div className="meterHeading">PRODUCTION JOURNALIER MAXIMUM</div>
            <ProgressBar  style={{ background: 'white' }} striped variant="success" animated now={bar1?.puissance||0} label={bar1?.puissance||0} min={0} max={20000} />
            </Col>
        </Row>
        <Row>
            <Col className="bottomMeter">
              <div className="meterHeading">VOTRE CONSOMMATION {bar2} (en Wh/j)</div>
              <ProgressBar className="progress-custom" striped animated now={bar2} label={bar2} min={0} max={20000}  style={{ background: 'white' }}/>
            </Col>
        </Row>

        {bar1?.puissance && bar2 && parseFloat(bar2) > parseFloat(bar1?.puissance) ? <div className="consumptionWarning">
          <p>
          {'Attention ce kit ne couvre pas vos besoins'}
            </p>
          </div> : null}
    </div>
  );
}

export default Meter;
