import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Simulator from './components/simulator/simulator'
import ProductContextProvider from './context/productContext'
import AddedEquipmentContextProvider from './context/addedEquipmentContext'

function App() {
  return (
    <div className="App">
      {/* Greenline Products Provider */}
      <ProductContextProvider>
          {/* Added Equipments by User Provider */}
          <AddedEquipmentContextProvider>
            {/* Simulator */}
            <Simulator />
          </AddedEquipmentContextProvider>
      </ProductContextProvider>
    </div>
  );
}

export default App;