import React, { useState, useContext, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { ProductContext } from "../../context/productContext"
import { Lightbox } from "react-modal-image";
import ModalImage from "react-modal-image";
import ProductModal from './../modals/productModal';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";


function ProductSelectionBottom(props) {
  const data = props.data;
  const { product, changeProduct } = useContext(ProductContext)
  const [showModal, setShowModal] = useState(false)
  const [productData, setProductData] = useState({})

  //Change the Product in Context
  const handleSelect = (selectedIndex, e) => {
    changeProduct(selectedIndex);
  };

  return (
    <>
      <div className="productSelectionBottom">
        <Row>
          <Col>
            <Carousel activeIndex={parseInt(product)} onSelect={handleSelect} indicators={false} interval={null}
              prevIcon={<BsFillArrowLeftCircleFill size={50} />}
              nextIcon={<BsFillArrowRightCircleFill size={50} />}
            >
              {data?.map((product, i) => (
                <Carousel.Item key={i}>
                  <Row style={{ justifyContent: 'center', display: 'flex' }}>
                    <Col md={2}>
                      <div class="productImage">
                        <ModalImage
                          medium={product.image}
                          large={product.image}
                          small={product.image}
                          alt={product?.name}
                          hideDownload={true}
                          className="modalImage"
                        />
                      </div>
                    </Col>
                    <Col md={4} className="btnContainer pt-2">
                      <div>
                        <h5 className="productHeading">{`${product.name} \n`}</h5>
                        {product.subTitle && <p style={{ textAlign: 'left', fontWeight: 200 }}>{`${product.subTitle}`}</p>}
                      </div>
                    </Col>
                  </Row>
                  {/* <div>{product.name}</div>
                <img src={product.image} /> */}
                  {/* <Carousel.Caption>
                  <h3>{product.puissance}</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>

        </Row>
      </div>


      <ProductModal isOpen={showModal} handleClose={() => setShowModal(false)} product={productData} />
    </>
  );
}

export default ProductSelectionBottom;
