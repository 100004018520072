import React, { useState } from "react";
import ChooseEquipments from './chooseEquipments'
import AddedEquipments from './addedEquipments'

function Equipments() {
  return (
    <div className="equipments">
        {/* Component to Choose Equipments */}
        <ChooseEquipments />

        {/* Selected Equipments will appear here */}
        <AddedEquipments />
    </div>
  );
}

export default Equipments;
