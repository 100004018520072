import React, { useState, useContext, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Image } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FaTimesCircle } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
// import Image from 'react-bootstrap/Badge';
import { AddedEquipmentContext } from "../../context/addedEquipmentContext";
import ProgressBar from "react-bootstrap/ProgressBar";
import { findIndex } from "lodash";

function AddedEquipment(props) {
  const { addedEquipment, removeEquipment, updateUsage, updateSelectedOption } =
    useContext(AddedEquipmentContext);
  const [quantity, setQuantity] = useState(1);
  const [option, setOption] = useState(null);
  const [optionId, setOptionId] = useState(0);
  const [optionName, setOptionName] = useState("");
  const [usage, setUsage] = useState(1);
  const [totalWatt, setTotalWatt] = useState(0);
  const [customProduct, setCustomProduct] = useState("");
  const [optionDetail, setOptionDetail] = useState({});
  const [bar, setBar] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [useEqt, setUseEqt] = useState(0);

  const equipmentAdded = props.equipment;
  const equipment = props.equipment;
  const index = props.index;
  //Remove Selected Equipment
  // const removeItemToArray = (obj) => {
  //   removeEquipment(obj);
  // };

  const removeaddedEquipment = (obj,index) => {
    removeEquipment(obj,index);
  };

  //Update Quantity
  const handleQuantity = (value) => {
    setQuantity(value);
    let val = Math.round(value * usage * option);
    updateSelectedOption(
      equipmentAdded?.id,
      value,
      usage,
      optionName,
      val,
      optionId
    );
    setTotalWatt(val);
    updateUsage(equipmentAdded.id, val);
  };

  //Update Equipment Option
  const handleOption = (id) => {
    let option = equipmentAdded?.options.find((i) => i.id == id);
    setOptionDetail(option);
    setOption(parseInt(option?.watt));
    setOptionName(option?.name);
    let val = Math.round(quantity * usage * parseInt(option?.watt));
    setOptionId(option?.id);
    if (option?.utilization) {
      updateSelectedOption(
        equipmentAdded?.id,
        quantity,
        option?.utilization,
        option?.name,
        val,
        option?.id
      );
    } else {
      updateSelectedOption(
        equipmentAdded?.id,
        quantity,
        usage,
        option?.name,
        val,
        option?.id
      );
    }
    setTotalWatt(val);
    updateUsage(equipmentAdded.id, val);
  };

  //Update Usage
  const handleUsage = (value) => {
    setUsage(value);
    let val = Math.round(quantity * value * option);
    setTotalWatt(val);
    updateSelectedOption(
      equipmentAdded?.id,
      quantity,
      value,
      optionName,
      val,
      optionId
    );
    updateUsage(equipmentAdded.id, val);
  };

  // useEffect(() => {
  //   if (equipmentAdded?.canCustomize && equipmentAdded?.canCustomize === true) {
  //     // setCustomProduct("");
  //   }
  // }, []);

  useEffect(() => {
    //Count the sum of Consumption
    let aeCount = 0;
    console.log(addedEquipment)
    addedEquipment.map((aE) => {
      aeCount += parseFloat(aE.usage);
    });
    setBar(aeCount);

    //Calculate Percentage
    // let percent = parseInt((useEqt / bar)*100);
    // setPercentage(percent);
  }, [useEqt, bar, addedEquipment]);

  return (
    <Card className="addedEquipment">
      {/* <div className="removeIcon"><a href="#" className="removeIconLink" onClick={() => removeItemToArray(equipmentAdded)}><FaTimesCircle /></a></div> */}

      <Container className="addedEquipmentContainer">
        <Row className="rowWidth">
          {/* Title with Badge
          {equipmentAdded?.canCustomize &&
            equipmentAdded?.canCustomize === true && (
              <Form.Control
                className="customProductInput"
                type="text"
                value={customProduct}
                // onChange={(e) => setCustomProduct(e.target.value)}
              />
            )} */}
          {/* If the Added Equipment has the quantity option */}
          {/* {!equipmentAdded?.canCustomize && <div className="addedEquipmentTitle">{equipmentAdded.name} </div>} */}
          {/* Options of the Equipment */}

          <div className="equipmentImgContainer">
            <div className="flexLeft ">
              <Image
                src={`${optionDetail?.image || equipmentAdded?.image}`}
                className="equipmentImage"
              />

<div>
                {equipmentAdded?.name}
              </div>

              {/* <Card.Img variant="top" src={`${optionDetail?.image || equipmentAdded?.image}`} className="equipmentImage" /> */}
              <div className="rowFormat">
                <Form.Select
                  size="sm"
                  className="addedEquipmentForm"
                  onChange={(e) => {
                    handleOption(e.currentTarget.value);
                  }}
                >
                  <option value={null}>Sélectionner</option>
                  {equipmentAdded.options?.map((o, i) => (
                    <option
                      value={o.id}
                      // value={parseInt(o.watt)}
                      name={o.name}
                    >
                      {o.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
              

              {equipmentAdded?.id <= 2 && (
                <div className="rowFormat2">
                  {equipmentAdded.multipleQuantity && (
                    <div>
                      {/* <div className="addedLabel">Quantité</div> */}
                      <Form.Select
                        size="sm"
                        className="addedEquipmentForm"
                        onChange={(e) => handleQuantity(e.currentTarget.value)}
                      >
                        <option value={null}>Quantité</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </Form.Select>
                    </div>
                  )}
                </div>
              )}

              {equipmentAdded?.id !== 3 && (
                <div className="rowFormat3">
                  {/* {equipmentAdded?.id !== 3 && (
                <div className="addedLabel">Utilisation</div>
              )} */}

                  <div>
                    <Form.Select
                      size="sm"
                      className="addedEquipmentForm"
                      onChange={(e) => handleUsage(e.currentTarget.value)}
                    >
                      <option value={null}>Utilisation</option>
                      <option value="0.08">5 minute</option>
                      <option value="0.16">10 minutes</option>
                      <option value="0.25">15 minutes</option>
                      <option value="0.5">30 minutes</option>
                      <option value="0.75">45 minutes</option>
                      <option value="1">1 heure</option>
                      <option value="2">2 heures</option>
                      <option value="3">3 heures</option>
                      <option value="4">4 heures</option>
                      <option value="5">5 heures</option>
                      <option value="6">6 heures</option>
                      <option value="7">7 heures</option>
                      <option value="8">8 heures</option>
                      <option value="9">9 heures</option>
                      <option value="10">10 heures</option>
                      <option value="11">11 heures</option>
                      <option value="12">12 heures</option>
                      <option value="13">13 heures</option>
                      <option value="14">14 heures</option>
                      <option value="15">15 heures</option>
                      <option value="16">16 heures</option>
                      <option value="17">17 heures</option>
                      <option value="18">18 heures</option>
                      <option value="19">19 heures</option>
                      <option value="20">20 heures</option>
                      <option value="21">21 heures</option>
                      <option value="22">22 heures</option>
                      <option value="23">23 heures</option>
                      <option value="24">24 heures</option>
                    </Form.Select>
                  </div>
                </div>
              )}

              {equipmentAdded?.showWarning && (
                <Row className="warningRow">
                  <Col className="warning nopadding">
                    <img
                      alt={equipmentAdded.name}
                      variant="top"
                      className="warningImage"
                      src="warning.png"
                    />
                    <span className="warningText">
                      <b>Appareils à résistance</b>
                      <br />
                      Batterie au Lithium nécessaire
                    </span>
                  </Col>
                </Row>
              )}
            </div>

            <div className="flexRight">
              <Badge bg="success" className="addedEquipmentBadge">
                {totalWatt} Wh/j
              </Badge>

              <div className="progressBarDiv">
                <div className="barText">
                  {parseInt((totalWatt / bar) * 100)}% de votre conso.
                </div>
                <ProgressBar
                  className="progress-custom"
                  variant="danger"
                  now={parseInt((totalWatt / bar) * 100)}
                  min={0}
                  max={100}
                  style={{ background: "gainsboro" }}
                />
              </div>

              {/* <div className="delImageDiv">
                <img
                  className="delImage"
                  onClick={() => removeaddedEquipment(equipmentAdded,index)}
                  src={require("./delete-button.png")}
                />
              </div> */}
            </div>
          </div>
        </Row>
        {/* <Row className="addedEquipmentRow"> */}
        {/* <Col className="addedEquipmentColLeft"> */}
        {/* <Badge bg="success" className="addedEquipmentBadge">{totalWatt} Wh/j</Badge> */}

        {/* Usage */}

        {/* </Col> */}

        {/* </Row> */}
      </Container>
    </Card>
  );
}

export default AddedEquipment;
