import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductSelection from '../productSelection/productSelection'
import Meter from '../meter/meter'
import Equipments from '../equipment/equipments'
import { ProductContext } from "../../context/productContext"
import Image from 'react-bootstrap/Image'

function Simulator() {
  const {product} = useContext(ProductContext)
  return (
    <Container className="container" fluid>
        <Row>
            <Col>
                {/* Logo */}
                <a href="https://www.greenline-madagascar.com/" target="_blank"><Image src={`logo.jpg`} className="logo" /></a>
            </Col>
        </Row>
        <Row>
            <Col>
                {/* Product Selection Component */}
                <ProductSelection />
            </Col>
        </Row>
        <Row>
            <Col>
                {/* Production and Comsumption Meters */}
                <Meter />
            </Col>
        </Row>
        <Row>
            <Col>
                {/* Equipments */}
                <Equipments />
            </Col>
        </Row>
    </Container>
  );
}

export default Simulator;
