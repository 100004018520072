import React, { useState, useContext, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ProductContext } from "../../context/productContext"
import { AddedEquipmentContext } from './../../context/addedEquipmentContext';

function ProductSelectionTop(props) {
  const { product, changeProduct } = useContext(ProductContext)
  const {totalConsumption} = useContext(AddedEquipmentContext)
  const products = props.data;

  //Change the Product in Context
  const handleSelect = (value) => {
    console.log('value...', value)
    changeProduct(value);
  };

  useEffect(() => {
    const activeProduct = products.find(i => i.id === product)
    if(activeProduct?.puissance < totalConsumption)
    {
      const find = products.find(i => i?.puissance >= totalConsumption)
      if(find != undefined) handleSelect(find.id)
    }
  }, [totalConsumption]);
  
  return (
    <div className="productSelectionTop">
        <Row className="centerData">
            <Col xs="10" md="6">
              <Form.Select onChange={(e)=>handleSelect(e.currentTarget.value)} className="productSelect" size="sm">
                {/* <option value="">Select</option> */}
                {products?.map( (p, i) => (
                  <option value={p.id} selected={p.id==product?true:false}>{p.name}</option>
                ))}
              </Form.Select>
            </Col>
        </Row>
    </div>
  );
}

export default ProductSelectionTop;
